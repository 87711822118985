exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-my-favorite-character-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=C:/Users/kywai/my-gatsby-site/blog/my-favorite-character/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-my-favorite-character-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-my-favorite-pet-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=C:/Users/kywai/my-gatsby-site/blog/my-favorite-pet/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-my-favorite-pet-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-my-first-post-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=C:/Users/kywai/my-gatsby-site/blog/my-first-post/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-my-first-post-index-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-recipes-js": () => import("./../../../src/pages/recipes.js" /* webpackChunkName: "component---src-pages-recipes-js" */),
  "component---src-templates-article-post-js": () => import("./../../../src/templates/articlePost.js" /* webpackChunkName: "component---src-templates-article-post-js" */),
  "component---src-templates-recipe-post-js": () => import("./../../../src/templates/recipePost.js" /* webpackChunkName: "component---src-templates-recipe-post-js" */)
}

